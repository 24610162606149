<template lang="pug">
div.container
  div(v-if="!activeMembers.length > 0")
    loading-spinner(message="Loading members...")
  div(v-if="activeMembers.length > 0")
    b-row(align-h="between" align-v="center")
      h1 Manage Members <router-link to="/individual-members/new"><b-button variant="success"><fa-icon icon="plus" size="lg"></fa-icon></b-button></router-link>
      b-form(inline)
        b-input(type="text" placeholder="search" v-model="searchText" @click="resultLimit = 10")

    div.jumbotron.justify-content-center.text-center
      h3 We have {{activeMembers.length}} active members:
      h4 {{activeLifetimeCount}} lifetime | {{activeIndividualCount}} individual | {{activeUnemployedCount}} unemployed | {{activeStudentCount}} student

    div.row
      h2 Active Memberships
      table.table.table-hover.table-striped
        thead.thead-dark
          tr
            th
            th Last Name
            th First Name
            th Membership Type
            th Expiry Date
        tbody
          tr.table-row-link(v-for="member in displayedActiveMembers" :key="member._id" @click="$router.push('/individual-members/' + member._id)")
            td
              fa-icon(v-if="member.status == 'lifetime'" icon="star")/
              fa-icon(v-else-if="member.status == 'valid'" icon="check")/
              fa-icon(v-else-if="member.status == 'expiring'" icon="clock")/
            td {{member.lastName}}
            td {{member.firstName}}
            td {{member.membershipType}}
            td
              span(v-if="member.membershipType == 'Lifetime'") -
              span(v-else) {{ member.validUntil | moment('MMMM YYYY')}}

    div.row.justify-content-center.text-center
      p {{searchActiveMembersResults.length}} search results
        span(v-show="displayedActiveMembers.length < searchActiveMembersResults.length")  ({{displayedActiveMembers.length}} shown)
    div.row.justify-content-center
      b-button(v-show="displayedActiveMembers.length < searchActiveMembersResults.length" size="sm" variant="info" @click="loadMore()") Load more

    div.row
      h2 Expired Memberships
      table.table.table-hover.table-striped
        thead.thead-dark
          tr
            th
            th Last Name
            th First Name
            th Membership Type
            th Expiry Date
        tbody
          tr.table-row-link(v-for="member in displayedInactiveMembers" :key="member._id" @click="$router.push('/individual-members/' + member._id)")
            td
              fa-icon(v-if="member.status == 'expired'" icon="times")/
            td {{member.lastName}}
            td {{member.firstName}}
            td {{member.membershipType}}
            td
              span(v-if="member.membershipType == 'Lifetime'") -
              span(v-else) {{ member.validUntil | moment('MMMM YYYY')}}

    div.row.justify-content-center.text-center
      p {{searchInactiveMembersResults.length}} search results
        span(v-show="displayedInactiveMembers.length < searchInactiveMembersResults.length")  ({{displayedInactiveMembers.length}} shown)
    div.row.justify-content-center
      b-button(v-show="displayedInactiveMembers.length < searchInactiveMembersResults.length" size="sm" variant="info" @click="loadMore()") Load more

    div.row.justify-content-center.mt-4
      b-button(variant="info" href="/api/individual-members/csv") Download All Individual Members as CSV
      b-button.ml-2(variant="info" href="/api/individual-members/valid/csv") Download Valid Individual Members as CSV
</template>

<script>
export default {
  data: function () {
    return {
      resultLimit: 10,
      activeMembers: [],
      inactiveMembers: [],
      searchText: '',
      activeIndividualCount: 0,
      activeUnemployedCount: 0,
      activeStudentCount: 0,
      activeLifetimeCount: 0,
      now: new Date(),
      expiryThreshold: new Date()
    }
  },
  computed: {
    searchActiveMembersResults: function () {
      return this.activeMembers.filter(this.searchFunction)
    },
    searchInactiveMembersResults: function () {
      return this.inactiveMembers.filter(this.searchFunction)
    },
    displayedActiveMembers: function () {
      return this.searchActiveMembersResults.slice(0, this.resultLimit)
    },
    displayedInactiveMembers: function () {
      return this.searchInactiveMembersResults.slice(0, this.resultLimit)
    }
  },
  methods: {
    searchFunction: function (member) {
      let s = this.searchText.toLowerCase()
      let hit = false

      // These could be cached but does it matter?
      let lastAndFirst = member.lastName + ' ' + member.firstName
      let firstAndLast = member.firstName + ' ' + member.lastName

      if (firstAndLast.toLowerCase().includes(s)) hit = true
      else if (lastAndFirst.toLowerCase().includes(s)) hit = true

      return hit
    },
    loadMore: function () {
      this.resultLimit += 20
    },
    sortByName: function (a, b) {
      if (!a.hasOwnProperty('lastName')) return 1
      if (!b.hasOwnProperty('lastName')) return -1
      let nameA = a.lastName.toLowerCase()
      let nameB = b.lastName.toLowerCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    }
  },
  mounted: function () {
    this.expiryThreshold.setFullYear(this.now.getFullYear(), this.now.getMonth() + 2, 0)

    this.axios.get('/api/individual-members')
      .then(res => {
        res.data.sort(this.sortByName)
        let actives = []; let inactives = []
        for (let member of res.data) {
          member.validUntil = new Date(member.validUntil)

          // For all active members...
          if (member.validUntil > this.now || member.membershipType === 'Lifetime') {
            // Membership type counters
            if (member.membershipType === 'Lifetime') { this.activeLifetimeCount++ } else if (member.membershipType === 'Individual') { this.activeIndividualCount++ } else if (member.membershipType === 'Student') { this.activeStudentCount++ } else { this.activeUnemployedCount++ }

            // Compute membership status
            if (member.validUntil <= this.expiryThreshold && member.membershipType !== 'Lifetime') {
              member.status = 'expiring'
            } else if (member.membershipType === 'Lifetime') {
              member.status = 'lifetime'
            } else {
              member.status = 'valid'
            }

            actives.push(member)
          } else {
            member.status = 'expired'
            inactives.push(member)
          }
        }

        // Push to DOM
        this.activeMembers = actives
        this.inactiveMembers = inactives
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching members failed',
          text: err.hasOwnProperty('response') ? err.response.data : err,
          duration: -1
        })
      })
  }
}
</script>
